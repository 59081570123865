<template>
	<div class="page">
		<lm-header :scrollTop="scrollTop" :bannerH="bannerH" :navH="navH" replaceHeader />
		<div ref="banner" class="banner">
			<div class="layout content">
				<div class="title">
					灵妙科技
					<br />
					企业数字化转型方案专家
				</div>
				<router-link tag="div" to="/relation" class="button">立即体验</router-link>
			</div>
		</div>
		<div ref="nav" class="navBox" :style="navStyle">
			<div class="nav">
				<div
					ref="nav0"
					class="title"
					:class="{ active: navActive == 0 }"
					@click="navActiveChange(0)"
				>
					公司介绍
				</div>
				<div
					ref="nav1"
					class="title"
					:class="{ active: navActive == 1 }"
					@click="navActiveChange(1)"
				>
					招贤纳士
				</div>
				<div class="activebox" :style="activeStyle">
					<div></div>
				</div>
			</div>
		</div>
		<div v-show="this.scrollTop >= this.bannerH" style="width:100px;height:80px;"></div>
		<div v-show="navActive == 0" class="introduce">
			<div class="text">
				<div class="title">公司简介</div>
				<div class="info">
					灵妙是一家致力于为政府及企事业单位数字化转型提供咨询与服务一体化解决方案的软件服务供应商。立足杭州，放眼世界，灵妙科技迄今已经具有6年的企业数字化实施经验，服务于全球20+大中型企业，客户遍及中国、欧洲、北美等多个国家和地区。灵妙将继续专注于不同行业的数字化转型与升级，从用户真实应用场景出发，解决企业业务管理应用的实际痛点，为更多企业客户提供专业的软件和优质的服务，推动企业管理更加智能、高效、透明，赋能企业经营，助力企业腾飞。
				</div>
			</div>
			<div class="imgBox">
				<div class="img">
					<div class="title">愿景</div>
					<div class="info">
						<p>成为领先的产业数字化</p>
						<p>平台服务商</p>
					</div>
				</div>
				<div class="img">
					<div class="title">核心价值观</div>
					<div class="info">
						<p>客户至上</p>
						<p>团结合作</p>
						<p>诚信敬业</p>
						<p>创新共赢</p>
					</div>
				</div>
			</div>
		</div>
		<div v-show="navActive == 0" class="contact">
			<div id="map" class="map"></div>
			<div class="text">
				<div class="title">联系我们</div>
				<div class="info">
					<div>电话</div>
					<div>400-800-9378</div>
					<div>邮箱</div>
					<div>
						<a href="mailto:service@lingmiaotech.com"
							>service@lingmiaotech.com</a
						>
					</div>
					<div>地址</div>
					<div>浙江省杭州市滨江区长河路351号3号楼C601室</div>
				</div>
			</div>
		</div>
		<div v-show="navActive == 1" class="Employment">
			<div class="title">欢迎加入灵妙科技</div>
			<div class="foot">
				<div>我们正在寻找更多才华横溢、怀揣梦想的你们，和我们一起做充满挑战的事情。</div>
				<div>简历邮箱投递地址：<a href="mailto:hr@lingmiaotech.com">hr@lingmiaotech.com</a></div>
			</div>
			<div class="flex flex-wrap list">
				<div
					class="card"
					v-for="(item, index) in employmentList"
					:key="'employmentList' + index"
				>
					<div class="title">{{ item.title }}</div>
					<div class="requirement">
						<div
							v-for="(info, idx) in item.requirement"
							:key="'Requirement' + idx"
						>
							<span>{{ idx + 1 }}、</span>{{ info }}
						</div>
					</div>
					<img src="./assets/招聘pt@2x.png" />
				</div>
			</div>
		</div>
		<lm-footer />
	</div>
</template>

<script>
import LmHeader from "@/components/header";
import LmFooter from "@/components/footer";
export default {
	components: {
		LmHeader,
		LmFooter,
	},
	computed: {
		activeStyle() {
			const style = {};
			style.width = this.navWidth[this.navActive] + "px";
			style.left = this.navLeft[this.navActive] + "px";
			return style;
		},
		navStyle() {
			let data = {}
			if (this.scrollTop >= this.bannerH) data = {
				position: 'fixed',
				top: 0,
				left: 0,
				zIndex: 100
			}
			else data = {}
			return data
		}
	},
	data() {
		return {
			scrollTop: 0,
			navActive: null,
			navWidth: [],
			navLeft: [],
			map: null,
			employmentList: [
				{
					title: "Java 工程师",
					requirement: [
						"Java基础扎实，理解IO、NIO，多线程、集合等 基础框架，有良好的编码习惯",
						"熟练使用Mysql、SQLServer、Oracle等关系数据库中的一种，有良好的SQL设计编写能力和对SQL优化有一定了解",
						"熟练使用Spring，SpringMVC，MyBatis，SpringBoot等常用框架",
						"思维清晰敏捷，有良好表达能力，逻辑分析能力强",
						"计算机相关专业大专及以上学历，二年工作经验及以上",
						"熟悉软件技术文档的编写；具备良好的文档编制习惯和代码书写规范；",
						"对微服务有一定了解的优先考虑",
					],
				},
				{
					title: "VUE工程师",
					requirement: [
						"熟练使用前端开发中的语⾔言，HTML，CSS，JS（ES6）；",
						"有Vue的实际项目经验，熟练应用 vue 的周边资源；",
						"掌握至少一种常用前端工程工具（Webpack/Gulp...）的使用；",
						"熟悉git操作",
						"能使用node（或其他后端语言）编写脚本和简单的服务；",
						"对敏捷开发方式有深入理解，并能搭建环境；",
						"突出的学习能力和逻辑思维能力，优秀的沟通能力。",
					],
				},
				{
					title: "销售经理",
					requirement: [
						"需要具备2年以上的项目类或者直销类销售经验，有软件相关销售经验者优先；",
						"对于公司高层关系运作有较丰富的经验；能够与客户建立较为信任的商务关系；",
						"热爱销售工作，热衷软件行业，能承受压力，有悟性；有较高的职业素养；",
						"人品优秀，敬业，工作认真、负责，具有良好的表达能力；",
						"身体健康，能适应经常出差；",
						"熟悉企业业务流程者优先。",
					],
				},
			],
			bannerH: 450,
			navH: 40
		};
	},
	mounted() {
		this.bannerH = this.$refs.banner.offsetHeight;
		this.navH = this.$refs.nav.offsetHeight;
		window.addEventListener("scroll", this.getScroll);
		this.navWidth = [
			this.$refs.nav0.clientWidth,
			this.$refs.nav1.clientWidth,
		];
		this.navLeft = [this.$refs.nav0.offsetLeft, this.$refs.nav1.offsetLeft];
		if(this.$route.params.index) {
			this.navActive = this.$route.params.index;
		} else {
			this.navActive = 0;
		}
		this.$nextTick(() => {
			this.draw();
		});
		this.getScroll();
	},
	methods: {
		getScroll() {
			this.scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop;
		},
		draw() {
			this.map = new BMap.Map("map");
			const poi = new BMap.Point(120.202036, 30.188953);
			this.map.centerAndZoom(poi, 17);
			this.map.enableScrollWheelZoom();
			var content =
				'<div style="margin:0;line-height:20px;padding:2px;">' +
				'地址：杭州市-滨江区-长河路351号<br/><span style="padding-left:35px;">拓森科技园3号楼C601室</span><br/>电话：400-880-9378' +
				"</div>";
			//创建检索信息窗口对象
			var searchInfoWindow = null;
			searchInfoWindow = new BMapLib.SearchInfoWindow(this.map, content, {
				title: "杭州灵妙科技有限公司", //标题
				width: 290, //宽度
				height: 70, //高度
				panel: "panel", //检索结果面板
				enableAutoPan: true, //自动平移
				searchTypes: [
					BMAPLIB_TAB_SEARCH, //周边检索
					BMAPLIB_TAB_TO_HERE, //到这里去
					BMAPLIB_TAB_FROM_HERE, //从这里出发
				],
			});
			var marker = new BMap.Marker(poi);
			this.map.addOverlay(marker);
			searchInfoWindow.open(marker);
		},
		navActiveChange(idx) {
			if (this.scrollTop > this.bannerH) document.body.scrollTop = document.documentElement.scrollTop = this.bannerH
			this.navActive = idx
		}
	},
};
</script>

<style lang="less" scoped>
.banner {
	width: 1920px;
	height: 450px;
	background: url("./assets/banner.png") no-repeat;
	background-size: 1920px;
	position: relative;
	.content {
		padding-top: 155px;
		line-height: 1;
		text-align: left;
		color: #ffffff;
		.title {
			font-size: 50px;
			padding-bottom: 30px;
			line-height: 1.2;
		}
		.info {
			font-size: 22px;
			padding-bottom: 53px;
		}
		.button {
			display: inline-block;
			font-size: 18px;
			padding: 15px 34px;
			background: #ff9000;
			border-radius: 4px;
			cursor: pointer;
		}
	}
}
.navBox {
	width: 100vw;
	box-shadow: 0px 6px 20px 0px rgba(142, 171, 201, 0.12);
	background-color: white;
	.nav {
		width: 1240px;
		height: 80px;
		margin: 0 auto;
		font-size: 16px;
		display: flex;
		position: relative;
		.title {
			padding-top: 30px;
			margin-right: 40px;
			cursor: pointer;
			transition: all 0.5s;
			&.active {
				color: #218aef;
			}
			&:hover {
				color: #218aef;
			}
		}
		.activebox {
			position: absolute;
			bottom: 0;
			left: 0;
			height: 3px;
			background: #218aef;
			min-width: 20px;
			transition: all 0.5s;
			text-align: center;
			div {
				display: inline-block;
				width: 55%;
				height: 20px;
				background: linear-gradient(to bottom, #ffffff, #d3ebff);
				transform: translateY(-20px);
			}
		}
	}
}
.introduce {
	width: 1240px;
	margin: 140px auto 140px;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	.text {
		width: 400px;
		text-align: justify;
		.title {
			font-size: 42px;
		}
		.info {
			font-size: 18px;
			color: #666666;
			line-height: 2;
			margin-top: 30px;
		}
	}
	.imgBox {
		display: flex;
		.img {
			width: 311px;
			height: 383px;
			margin-left: 10px;
			padding: 50px;
			box-sizing: border-box;
			text-align: left;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			background-repeat: no-repeat;
			background-size: 311px;
			&:nth-of-type(1) {
				background-image: url("./assets/愿景bj@2x.png");
			}
			&:nth-of-type(2) {
				background-image: url("./assets/价值观bj@2x.png");
				.info {
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;
				}
			}
			.title {
				font-size: 42px;
				color: #ffffff;
				line-height: 1;
			}
			.info {
				font-size: 18px;
				color: #ffffff;
			}
		}
	}
}
.contact {
	width: 1240px;
	margin: 0 auto 140px;
	display: flex;
	justify-content: space-between;
	.map {
		width: 613px;
		height: 363px;
		border: 4px solid rgba(186, 210, 232, 0.46);;
	}
	.text {
		width: 491px;
		height: 363px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		text-align: left;
		.title {
			font-size: 42px;
		}
		.info {
			font-size: 18px;
			& > :nth-child(odd) {
				font-size: 18px;
				color: #218aef;
			}
			& > :nth-child(odd) {
				font-size: 18px;
				margin-top: 10px;
			}
			a {
				color: #333333;
				font-size: 18px;
			}
		}
	}
}
.Employment {
	width: 1240px;
	margin: 100px auto 100px;
	.title {
		font-size: 42px;
		line-height: 1;
		margin-bottom: 40px;
	}
	.list {
		.card {
			width: 400px;
			background-color: #ffffff;
			box-shadow: 0px 8px 70px 0px rgba(142, 171, 201, 0.2);
			margin: 0 10px 10px 0;
			padding: 35px 30px 30px;
			position: relative;
			text-align: left;
			&:nth-of-type(3n) {
				margin-right: 0;
			}
			.title {
				font-size: 28px;
			}
			.requirement {
				font-size: 16px;
				div {
					margin-top: 10px;
					color: #666666;
					span {
						color: #218aef;
					}
				}
			}
			img {
				width: 147px;
				position: absolute;
				top: 0;
				right: 30px;
			}
		}
	}
}
	.foot {
		margin-bottom: 40px;
		font-size: 18px;
	}
</style>
